<template>
    <div class="sidebar-thin custom-container">
        <TheSidebar :fullSize="false" />

        <ThePageHeadingBar
            :pageHeading="pageHeading"
            backArrow
            fullSize
            @goBack="$router.push( { name: 'RRASearch' } )"
        />

        <section class="main">
            <RRASummaryDetail
                :loading="createInFlight"
                :location.sync="location"
                :readyToSubmit="readyToSubmit"
                :supplier.sync="supplier"
                :notes.sync="notes"
                isCreate
                @generate="generate()"
            />
        </section>
    </div>
</template>

<script>
// Vuex
import {
    mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';

// Utils
import ObjectUtil from '@/utils/ObjectUtil';
import FormatUtil from '@/utils/FormatUtil';

// Components
import ThePageHeadingBar from '@/components/ThePageHeadingBar';
import TheSidebar from '@/views/common/TheSidebar';
import RRASummaryDetail from './common/RRASummaryDetail';

export default {
    name: 'RRACreate',
    components: {
        TheSidebar,
        ThePageHeadingBar,
        RRASummaryDetail,
    },
    computed: {
        ...mapState( {
            pendingRRA: ( state ) => state.RRA.Create.pendingRRA,
            locationList: ( state ) => state.GlobalResources.locationList,
            primarySupplierList: ( state ) => state.GlobalResources.primarySupplierList,
        } ),
        ...mapGetters( {
            readyToSubmit: 'RRA/Create/isReadyToSubmit',
        } ),
        pageHeading() {
            let heading = 'RRA Detail';
            if ( ObjectUtil.isNotEmpty( this.supplier ) ) {
                heading += `, ${ this.supplier.name }`;
            }
            heading += ` - ${ FormatUtil.epochToSimpleString( Date.now() ) }`;
            return heading;
        },
        location: {
            get() {
                return this.pendingRRA.location;
            },
            set( value ) {
                this.setLocation( { location: value } );
            },
        },
        supplier: {
            get() {
                return this.pendingRRA.supplier;
            },
            set( value ) {
                this.setSupplier( { supplier: value } );
            },
        },
        notes: {
            get() {
                return this.pendingRRA.notes;
            },
            set( value ) {
                this.setNotes( { notes: value } );
            },
        },
        createInFlight: {
            get() {
                return this.$store.state.RRA.Create.inFlight;
            },
            set( val ) {
                this.$store.commit( 'RRA/Create/SET_IN_FLIGHT', { inFlight: val } );
            },
        },
    },
    async beforeMount() {
        this.createInFlight = true;
        try {
            await this.$store.dispatch( 'GlobalResources/init' );
            if ( this.$router.currentRoute.query ) {
                const { locationId, supplierId } = this.$router.currentRoute.query;
                if ( locationId ) {
                    this.location = this.locationList.find( ( location ) => ( location.id ).toString() === locationId ) ?? this.location;
                }
                if ( supplierId ) {
                    this.supplier = this.primarySupplierList.find( ( supplier ) => ( supplier.id ).toString() === supplierId ) ?? this.supplier;
                }
            }
        } finally {
            this.createInFlight = false;
        }
    },
    methods: {
        ...mapMutations( 'RRA/Create', {
            setLocation: 'SET_LOCATION',
            setSupplier: 'SET_SUPPLIER',
            setNotes: 'SET_NOTES',
        } ),
        ...mapActions( {
            createRRA: 'RRA/Create/createRRA',
        } ),
        async generate() {
            this.createInFlight = true;
            const createdRRA = await this.createRRA();
            if ( createdRRA?.id ) {
                // For once, JS cares about the type. But really tho, params' id needs to be a string.
                await this.$router.push( {
                    name: 'RRADetail',
                    params: { id: createdRRA.id.toString() },
                } );
            }
        },
    },
};
</script>

<style scoped>
</style>
