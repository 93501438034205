<template>
    <div class="sidebar-thin custom-container">
        <TheSidebar/>

        <ThePageHeadingBar
            :pageHeading="pageHeading"
            backArrow
            fullSize
            @goBack="$router.push( { name: 'RRASearch' } )"
        />

        <section class="main">
            <ModalConfirmation
                v-model="failedToFindRRADialog"
                confirmationTitle="RRA not found"
                hideCancelButton
                maxWidth="200px"
                persistent
                primaryButtonText="Okay"
                @confirm="goToSearch()"
            />
            <ModalConfirmation
                v-model="removalConfirmationDialog"
                cancelButtonColor="info"
                confirmationText="Do you want to remove the selected RRA Lines?"
                confirmationTitle="Remove RRA Lines"
                headerClass="info"
                maxWidth="350px"
                persistent
                primaryButtonColor="info"
                primaryButtonText="Remove"
                @confirm="removeLines()"
            />
            <ModalConfirmation
                v-model="unsavedChangesDialog"
                cancelButtonColor="warning"
                confirmationText="Changes must be saved before continuing."
                confirmationTitle="Unsaved Changes"
                headerClass="warning"
                maxWidth="350px"
                persistent
                primaryButtonColor="warning"
                primaryButtonText="Save"
                @confirm="saveButtonHandler"
            />

            <div class="summary-section">
                <RRASummaryDetail
                    :createDate="detailInfo.createDate"
                    :isNotesDisabled="isStatusCanceledOrComplete"
                    :isStatusDisabled="isDisabled"
                    :loading="inFlight"
                    :location="detailInfo.location"
                    :notes.sync="notes"
                    :rraNumber="detailInfo.number"
                    :status.sync="status"
                    :supplier="detailInfo.supplier"
                    :totals="{
                        lines: totalLines,
                        units: totalUnits,
                        value: totalValue,
                    }"
                />
            </div>

            <v-card class="rra-lines-section">
                <h2>RRA Lines</h2>
                <ClTableFilterable
                    :filter="tableRRALines.filter"
                    :headers="tableRRALines.headers"
                    :items="detailInfo.items"
                    :selected.sync="tableRRALines.selected"
                    :sortBy.sync="tableSortBy"
                    :sortDesc.sync="tableSortDesc"
                    outlined
                    showSelect
                    @click:row="( item ) => openExpandedEan( item )"
                    @current-items="( payload ) => updateCurrentItems( payload )"
                >
                    <template #[`item.hasTitleDesignators`]="{ item }">
                        <div
                            v-if="item.hasTitleDesignators"
                            aria-label="True"
                            class="capsule capsule--filled"
                        />
                        <div
                            v-else
                            aria-label="False"
                            class="capsule"
                        />
                    </template>
                    <template #[`item.title`]="{ item }">
                        {{ item.title || 'Not Set' }}
                    </template>
                    <template #[`item.priceReturned`]="{ item }">
                        {{ FormatUtil.toCurrency(item.priceReturned) }}
                    </template>
                    <template #filter>
                        <v-text-field
                            v-model="tableRRALines.filter"
                            appendIcon="mdi-magnify"
                            dense
                            hideDetails
                            label="Filter Results..."
                        />
                    </template>
                    <template #activator>
                        <ModalAddRRALine
                            :disabled="isStatusCanceledOrComplete"
                            @addLine="openExpandedEan"
                            @activator:click="checkUnsaved"
                        />
                    </template>
                </ClTableFilterable>
            </v-card>
        </section>

        <ThePageFooterBar>
            <div class="footer">
                <div class="footer__left">
                    <ClButton
                        :disabled="!hasRRALineItemsSelected || isStatusCanceledOrComplete || removeInFlight"
                        :loading="removeInFlight"
                        :rounded="false"
                        type="secondary"
                        @click="checkUnsaved(removeButtonHandler)"
                    >
                        Remove
                    </ClButton>
                    <ModalRMANumber
                        :rmaNumber="hasSingleRRALineItemSelected ? tableRRALines.selected[0].rma : ''"
                        :reason="hasSingleRRALineItemSelected ? tableRRALines.selected[0].reason : ''"
                        @update="setRMAButtonHandler"
                        @activator:click="checkUnsaved"
                    >
                        <template v-slot="{ attrs, on }">
                            <ClButton
                                :disabled="!hasRRALineItemsSelected || isStatusCanceledOrComplete"
                                :rounded="false"
                                class="rma-number__activator-button"
                                type="secondary"
                                v-bind="attrs"
                                v-on="on"
                            >
                                Set RMA
                            </ClButton>
                        </template>
                    </ModalRMANumber>
                </div>
                <div>
                    <ClButton
                        :rounded="false"
                        type="secondary"
                        @click="exportButtonHandler()"
                    >
                        Export
                    </ClButton>
                </div>
                <div class="footer__right">
                    <ClButton
                        :disabled="inFlight"
                        :loading="inFlight"
                        :rounded="false"
                        @click="saveButtonHandler()"
                    >
                        Save
                    </ClButton>
                    <ClButton
                        :disabled="inFlight"
                        :loading="inFlight"
                        :rounded="false"
                        @click="saveAndNextButtonHandler()"
                    >
                        Save and Next
                    </ClButton>
                </div>
            </div>
        </ThePageFooterBar>
    </div>
</template>
<script>
// Vuex
import { mapGetters, mapState } from 'vuex';

// Utils
import ObjectUtil from '@/utils/ObjectUtil';
import FormatUtil from '@/utils/FormatUtil';

// Components
import ThePageHeadingBar from '@/components/ThePageHeadingBar';
import TheSidebar from '@/views/common/TheSidebar';
import ThePageFooterBar from '@/components/ThePageFooterBar';
import ModalConfirmation from '@/components/modals/ModalConfirmation';
import RRASummaryDetail from './common/RRASummaryDetail';
import ModalAddRRALine from './detail/ModalAddRRALine';
import ModalRMANumber from './common/ModalRMANumber';

export default {
    name: 'RRADetail',
    components: {
        TheSidebar,
        ThePageHeadingBar,
        RRASummaryDetail,
        ThePageFooterBar,
        ModalConfirmation,
        ModalAddRRALine,
        ModalRMANumber,
    },
    beforeRouteLeave( to, from, next ) {
        if ( this.isDirty ) {
            this.checkUnsaved( next );
        } else {
            next();
        }
    },
    data() {
        return {
            FormatUtil,
            callback: null,
            failedToFindRRADialog: false,
            removalConfirmationDialog: false,
            removeInFlight: false,
            unsavedChangesDialog: false,
            tableRRALines: {
                filter: '',
                selected: [],
                headers: [
                    {
                        text: 'EAN',
                        value: 'isbn',
                    },
                    {
                        text: 'Condition',
                        value: 'condition',
                    },
                    {
                        text: 'RMA Number',
                        value: 'rma',
                    },
                    {
                        text: 'Designators',
                        value: 'hasTitleDesignators',
                        align: 'start',
                    },
                    {
                        text: 'Title',
                        value: 'title',
                    },
                    {
                        text: 'Return Qty',
                        value: 'quantityReturned',
                        align: 'end',
                    },
                    {
                        text: 'Return Amount',
                        value: 'priceReturned',
                        align: 'end',
                    },
                ],
            },
        };
    },
    computed: {
        ...mapGetters( {
            totalLines: 'RRA/Detail/totalLines',
            totalUnits: 'RRA/Detail/totalUnits',
            totalValue: 'RRA/Detail/totalValue',

            canGoToNext: 'RRA/Detail/canGoToNext',
            hasRRAItems: 'RRA/Detail/hasRRAItems',
            indexOfCurrentTableRRA: 'RRA/Detail/indexOfCurrentTableRRA',

            isStatusCanceledOrComplete: 'RRA/Detail/isStatusCanceledOrComplete',
            isDisabled: 'RRA/Detail/isDisabled',
        } ),
        ...mapState( {
            inFlight: ( state ) => state.RRA.Detail.inFlight,
            isDirty: ( state ) => state.RRA.Detail.isDirty,
            detailInfo: ( state ) => state.RRA.Detail.info,
            rraSearchResultTableList: ( state ) => state.RRA.Search.table.rraList,
            tableRRALinesInfo: ( state ) => state.RRA.Detail.tableRRALines,
        } ),
        /** @returns { String } */
        pageHeading() {
            let heading = 'RRA Detail';
            if ( ObjectUtil.isNotEmpty( this.detailInfo.supplier ) && !!this.detailInfo.supplier.name ) {
                heading += `, ${ this.detailInfo.supplier.name }`;
            }
            if ( this.detailInfo.createDate ) {
                heading += ` - ${ FormatUtil.dateTimeToSimpleString( this.detailInfo.createDate ) }`;
            }
            return heading;
        },
        status: {
            get() {
                return this.detailInfo.status;
            },
            set( value ) {
                this.$store.commit( 'RRA/Detail/SET_STATUS', { status: value } );
                this.$store.commit( 'RRA/Detail/SET_IS_DIRTY', { isDirty: true } );
            },
        },
        notes: {
            get() {
                return this.detailInfo.notes;
            },
            set( value ) {
                this.$store.commit( 'RRA/Detail/SET_NOTES', { notes: value } );
                this.$store.commit( 'RRA/Detail/SET_IS_DIRTY', { isDirty: true } );
            },
        },
        tableSortBy: {
            get() {
                return this.tableRRALinesInfo.sortBy;
            },
            set( value ) {
                this.$store.commit( 'RRA/Detail/SET_TABLE_SORT_BY', { sortBy: value } );
            },
        },
        tableSortDesc: {
            get() {
                return this.tableRRALinesInfo.sortDesc;
            },
            set( value ) {
                this.$store.commit( 'RRA/Detail/SET_TABLE_SORT_DESC', { sortDesc: value } );
            },
        },
        /** @returns { Boolean } */
        hasRRALineItemsSelected() {
            return this.tableRRALines.selected.length > 0;
        },
        /** @returns { Boolean } */
        hasSingleRRALineItemSelected() {
            return this.tableRRALines.selected.length === 1;
        },
    },
    async mounted() {
        if ( this.$route.params.id ) {
            await this.getRRADetails();
        }
    },
    beforeMount() {
        window.addEventListener( 'beforeunload', this.preventNav );
        this.$once( 'hook:beforeDestroy', () => {
            window.removeEventListener( 'beforeunload', this.preventNav );
        } );
    },
    methods: {
        openExpandedEan( item ) {
            this.$router.push( {
                name: 'RRAEANDetail',
                params: {
                    ean: item.isbn,
                    condition: item.condition,
                    id: this.detailInfo.id,
                },
            } );
        },
        // This function prevents the browser from navigating while editing.
        preventNav( event ) {
            if ( !this.isDirty ) return;
            event.preventDefault();
            // eslint-disable-next-line no-param-reassign
            event.returnValue = '';
        },
        async getRRADetails() {
            try {
                await this.$store.dispatch( 'RRA/Detail/get', {
                    id: this.$route.params.id,
                } );
            } catch ( error ) {
                this.failedToFindRRADialog = true;
            }
        },
        async goToSearch() {
            await this.$router.push( '/rra' );
        },
        removeButtonHandler() {
            this.removalConfirmationDialog = true;
        },
        async removeLines() {
            this.removeInFlight = true;
            try {
                await this.$store.dispatch( 'RRA/Detail/update', {
                    itemsToBeRemoved: this.tableRRALines.selected,
                } );
                this.tableRRALines.selected = [];
            } finally {
                this.removeInFlight = false;
                this.removalConfirmationDialog = false;
            }
        },
        exportButtonHandler() {
            this.$store.dispatch( 'RRA/Detail/export' );
        },
        setRMAButtonHandler( { rmaNumber, reason } ) {
            this.$store.dispatch( 'RRA/Detail/setRMADetails', {
                rmaNumber,
                reason,
                selected: this.tableRRALines.selected,
            } );
            this.saveButtonHandler();
            this.tableRRALines.selected = [];
        },
        async saveButtonHandler() {
            try {
                await this.$store.dispatch( 'RRA/Detail/update' );
                this.unsavedChangesDialog = false;
                if ( this.callback ) {
                    this.callback();
                    this.callback = null;
                }
            } catch ( e ) {
                await this.$store.dispatch( 'setErrorNotification', `Error Saving RRA: ${ e.message }` );
                throw new Error( e.message );
            }
        },
        async saveAndNextButtonHandler() {
            await this.saveButtonHandler();
            if ( this.canGoToNext ) {
                const nextItem = this.rraSearchResultTableList[ this.indexOfCurrentTableRRA + 1 ];
                // Update the URL
                await this.$router.push( {
                    name: 'RRADetail',
                    params: { id: `${ nextItem.id.toString() }` },
                } );
                // Go get the next RRA's info
                await this.$store.dispatch( 'RRA/Detail/get', nextItem );
            } else {
                await this.goToSearch();
            }
        },
        async checkUnsaved( callback ) {
            if ( this.isDirty ) {
                this.unsavedChangesDialog = true;
                this.callback = callback;
            } else {
                callback();
            }
        },
        updateCurrentItems( items ) {
            /** @type { String } */
            const existingItemIds = this.detailInfo.items.map( ( item ) => item.id ).join( '' );
            /** @type { String } */
            const incomingItemIds = items.map( ( item ) => item.id ).join( '' );

            // Set the table items if we don't already have items, or the items are in a new order
            if ( !this.hasRRAItems || ( existingItemIds !== incomingItemIds ) ) {
                this.$store.commit( 'RRA/Detail/SET_TABLE_ITEMS', { items } );
            }
        },
    },
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/variables.scss";

.footer {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &__left > *,
    &__right > *:not(:last-child) {
        margin-right: 1rem;
    }
}

.rra-lines-section {
    padding: 1rem;
    margin-bottom: 6rem;
}

.activator-button {
    margin-left: 1.5rem;
}

.capsule {
    width: 2.5rem;
    height: 1rem;
    border-radius: .5rem;
    border: 1px solid $gray;
    margin-left: .75rem;

    &--filled {
        background-color: $gray;
    }
}
</style>
