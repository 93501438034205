<template>
    <ClModal
      v-model="showModal"
      width="400px"
      class='add-rra-line'
  >
        <template #activator="{ attrs }">
            <ClButton
                type="secondary"
                class="add-rra-line__activator-button"
                :disabled="disabled"
                :rounded="false"
                v-bind="attrs"
                @click="handleClick"
            >
                Add RRA Line
            </ClButton>
        </template>
        <v-card>
            <v-card-title class="add-rra-line__title headline">
                Add RRA line
            </v-card-title>
            <ClButton
                icon
                aria-label="Close Modal"
                color="white"
                class="add-rra-line__close-button"
                @click="showModal = false"
            >
                <v-icon>mdi-close</v-icon>
            </ClButton>
            <v-container class="add-rra-line__content">
                <v-row>
                    <v-col cols="12">
                        <ClInput
                            v-model="ean"
                            type="text"
                            label="EAN"
                        />
                    </v-col>
                    <v-col cols="12">
                        <DropdownCondition
                            v-model="condition"
                            label="Condition"
                        />
                    </v-col>
                </v-row>
            </v-container>
            <v-card-actions class="add-rra-line__actions">
                <v-spacer />
                <ClButton
                    type="secondary"
                    :rounded="false"
                    @click="showModal=false"
                >
                    Cancel
                </ClButton>
                <ClButton
                    type="primary"
                    :rounded="false"
                    @click="addLine"
                >
                    Add
                </ClButton>
            </v-card-actions>
        </v-card>
    </ClModal>
</template>

<script>
import DropdownCondition from '@/views/common/dropdowns/DropdownCondition';

export default {
    name: 'ModalAddRRALine',
    components: {
        DropdownCondition,
    },
    props: {
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            showModal: false,
            ean: '',
            condition: 'NEW',
        };
    },
    methods: {
        addLine() {
            this.$emit( 'addLine', {
                isbn: this.ean,
                condition: this.condition,
            } );
        },
        handleClick() {
            this.$emit( 'activator:click', this.openModal );
        },
        openModal() {
            this.showModal = true;
        },
    },
};
</script>

<style lang="scss" scoped>
    .add-rra-line {
        &__activator-button {
            margin-left: 1rem;
        }
        &__content {
            padding: 1.5rem 1rem 1rem;
        }
        &__title{
            background-color: var(--v-primary-base);
            color: white;
            position: relative
        }
        &__close-button {
            position: absolute;
            top: 11px;
            right: 16px;
        }
        &__actions {
            padding-bottom: 1rem;
        }
    }
</style>
